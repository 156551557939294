import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import { IPartnersTemplateData } from "@types";
import { YoastSEOType } from "../../assets/Types";
import B2BBrandContainer from "../../containers/B2BContainer/B2BBrandContainer";
import BrandCampaignHeroContainer from "../../containers/BrandCampaignHeroContainer/BrandCampaignHeroContainer";
import DefaultLayoutShortNav from "../../layouts/DefaultLayoutShortNav";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import LogoHeader from "../../components/Headers/LogoHeader";
import useSetPromocode from "../../hooks/useSetPromocode";
import {
  BookingToBillingToggleType,
  BrandCampaignHeroType,
  FeaturesModulePType,
  GlobalFooterLargeCtaHType,
  SmartDisplayType,
  TopNavigationCtaType,
} from "@types";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    title: string;
    template: {
      bookingToBillingToggle: BookingToBillingToggleType;
      brandCampaignHero: BrandCampaignHeroType;
      featuresModuleP: FeaturesModulePType;
      globalFooterLargeCtaH?: GlobalFooterLargeCtaHType;
      smartDisplay: SmartDisplayType;
      topNavigationCta: TopNavigationCtaType;
    };
  };
};

const BrandCampaignPage: React.FC<PageProps<DataProps, IPartnersTemplateData>> =
  ({ data, location }) => {
    const { wpPage } = data;
    const {
      template: {
        bookingToBillingToggle,
        featuresModuleP,
        brandCampaignHero: { brandCampaignRemoveCta },
        brandCampaignHero,
        globalFooterLargeCtaH,
        smartDisplay: { smartDisplayToggle },
        topNavigationCta: { ctaLink, ctaText },
        partnerPromoPg: { partnerPromo },
        isPartnerCookie: { isPartner },
      },
    } = wpPage;
    useSetPromocode(partnerPromo, isPartner);

    const globalFooterLargeCtaHProps = {
      ...globalFooterLargeCtaH,
      navCtaLink: ctaLink,
      navCtaText: ctaText,
      smartDisplayToggle,
    };

    let headerStyle;

    if (brandCampaignRemoveCta === "hide") {
      headerStyle = <LogoHeader />;
    } else {
      headerStyle = null;
    }

    return (
      <DefaultLayoutShortNav
        pathname={location.pathname}
        globalFooterLargeH={globalFooterLargeCtaHProps}
        isTransparent
        hasNoHeader={headerStyle !== null ? true : false}
        hasFooterLargeCta
        alter
        isFixed
        isTracked
      >
        {headerStyle}

        {wpPage.seo && (
          <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
        )}
        <BrandCampaignHeroContainer data={brandCampaignHero} alter noHeader />
        <B2BBrandContainer toggle={bookingToBillingToggle} />
        <FeaturesModulePContainer
          page={"Brand Campaign"}
          props={featuresModuleP}
        />
      </DefaultLayoutShortNav>
    );
  };

export const query = graphql`
  query BrandCampaignTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpBrandCampaignTemplate {
          ...FragmentBookingToBillingToggleBrandP
          ...FragmentBrandCampaignHeroP
          ...FragmentBrandCampaignPromoP
          ...FragmentFeaturesModuleBrandP
          ...FragmentGlobalFooterLargeCtaHBrandP
          ...FragmentSmartDisplayBrandP
          ...FragmentTopNavigationCtaBrandP
        }
      }
    }
  }
`;

export default BrandCampaignPage;
