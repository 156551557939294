import React from "react";

import blueLogo from "./blueLogo.svg";
import whiteLogo from "./whiteLogo.svg";
import butterflyLogo from "./butterflyLogo.svg";

type LogosVariantType = "sp-white" | "sp-butterfly";

type SimplePracticeLogosType = {
  variant?: LogosVariantType;
  [key: string]: any;
};

const SimplePracticeLogo: React.FC<SimplePracticeLogosType> = ({
  variant,
  ...props
}) => {
  const opts = {
    "sp-white": whiteLogo,
    "sp-butterfly": butterflyLogo,
  };

  const src = opts[variant] ? opts[variant] : blueLogo;

  return <img {...props} src={src} alt="simplepractice logo" />;
};

export default SimplePracticeLogo;
