import React from "react";
import BlueLinkWithArrow from "../../components/Buttons/BlueLinkWithArrow/BlueLinkWithArrow";
import BookingToBillingDesktop from "../../components/BookingToBilling/index";
import { BookingToBillingToggleType } from "@types";
import BookingToBillingQuery from "../../assets/StaticQueries/BookingToBilling.query";

import styles from "../B2BContainer/B2BContainer.module.scss";

type B2BBrandContainerPropsType = {
  className?: string;
  toggle?: BookingToBillingToggleType;
};

const B2BBrandContainer: React.FC<B2BBrandContainerPropsType> = ({
  toggle,
}) => {
  const b2b = BookingToBillingQuery();

  return (
    <div>
      {toggle?.bookingToBillingToggle !== "off" && (
        <section className={styles.b2bContainerAlter}>
          <div className={`full-width w--704 text--alignment--center`}>
            <h2
              className={styles.b2bTitle}
              dangerouslySetInnerHTML={{ __html: b2b?.title! }}
            ></h2>
            <div className={styles.b2bSubcopyContainer}>
              <span className={styles.b2bSubcopy}>
                {b2b?.subcopy!}
                <BlueLinkWithArrow
                  className={styles.inlineLink}
                  link={b2b?.featureLink!}
                  linkCopy={b2b?.featureLinkText!}
                />
              </span>
            </div>
            <BookingToBillingDesktop
              bookingToBillingFeatureList={b2b?.featureList}
            />
          </div>
        </section>
      )}
    </div>
  );
};

export default B2BBrandContainer;
