import React from "react";
import SimplePracticeLogo from "../../SVGImages/SimplePracticeLogo/SimplePracticeLogo";
import styles from "./LogoHeader.module.scss";

const LogoHeader: React.FC = () => {
  return (
    <header className={styles.logoHeaderContainer}>
      <div className={styles.logoHeaderWrapper}>
        <a href="/">
          <figure className={styles.logoHeaderLogo}>
            <SimplePracticeLogo />
          </figure>
        </a>
      </div>
    </header>
  );
};

export default LogoHeader;
